"use server";

import { getRequestEvent } from "solid-js/web";
import {
  getAllConversations,
  getPlutoCredits,
  getUserProfile,
} from "../apis/server_apis";
import { ChatDetails, Credits } from "../types/pluto";
import { getCookie } from "vinxi/http";
import { Cookie } from "~/types";
import { redirect } from "@solidjs/router";
import { UserProfile } from "../types/user";
import { APIError } from "~/utils/fetch";

export type PlutoRouteData = {
  conversations?: ChatDetails[];
  credits?: Credits;
  userName?: string;
};

export const getPlutoRouteData = async (): Promise<PlutoRouteData> => {
  let conversations: ChatDetails[] | undefined;
  let credits: Credits | undefined;
  let userProfile: UserProfile | undefined;

  let sid = getCookie(Cookie.SessionId);

  if (!sid) {
    throw redirect("/pluto/login");
  }

  let conversations$P = getAllConversations({
    throwAuthError: true,
  });
  let credits$P = getPlutoCredits({
    throwAuthError: true,
  });
  let userProfile$P = getUserProfile(
    {},
    {
      throwAuthError: true,
    }
  );

  try {
    let resolved = await Promise.all([
      conversations$P,
      credits$P,
      userProfile$P,
    ]);

    conversations = resolved[0];
    credits = resolved[1];
    userProfile = resolved[2];

    if (!userProfile.firstName) {
      throw redirect("/pluto/login");
    }
  } catch (error) {
    if (error instanceof APIError && error.code === 401) {
      throw redirect("/pluto/login");
    }
    throw error;
  }

  return {
    conversations,
    credits,
    userName:
      `${userProfile?.firstName ?? ""} ${userProfile?.lastName ?? ""}`.trim(),
  };
};
